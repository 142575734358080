import {
  createTheme,
  outlinedInputClasses,
  inputLabelClasses,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import variables from 'styles/variables.module.scss';

const { defaultFontFamily: fontFamily } = variables;

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily,
          textTransform: 'none',
          fontWeight: 400,
          border: 'solid 1px #6ffffd',
          color: '#6ffffd',
          borderRadius: '6px',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontFamily,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#090a39',
          borderRadius: '6px',
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#bebeff',
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
      styleOverrides: {
        root: {
          fontFamily,
          borderColor: 'transparent',
        },
        icon: {
          color: '#bebeff',
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontFamily,
          borderBottom: '0px solid rgba(224, 224, 224, 1)',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontFamily,
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          [`&.${inputLabelClasses.focused}`]: {
            color: '#fff',
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'transparent',
        },
        input: { color: '#fff' },
        '&::placeholder': {
          color: '#adadd0 !important',
        },
        root: {
          backgroundColor: 'rgb(255,255,255,0.1)',
          fontFamily,
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#bebeff',
          },
          /* Reset on touch devices */
          '@media (hover: none)': {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#004BB9',
            },
          },
          [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: '#bebeff',
            },
          [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: 'red',
            },
          [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
            {
              borderColor: '#434A4F',
            },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
          fontFamily,
        },
      },
    },

    MuiCheckbox: {
      variants: [
        {
          props: {
            variant: 'vibrant',
          },
          style: {
            '& .MuiSvgIcon-root': {
              color: '#6ffffd',
            },
          },
        },
      ],
    },

    MuiRadio: {
      variants: [
        {
          props: {
            variant: 'dark',
          },
          style: {
            '& .MuiSvgIcon-root': {
              color: '#10006a',
            },
          },
        },
      ],
    },
  },

  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    turqouis: {
      main: '#6ffffd',
      light: '#6ffffd',
      dark: '#6ffffd',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    background: {
      light: '#403b76',
      dark: '#403b76',
      contrastText: '#403b76',
      main: '#322c72',
    },
    lightPink: {
      light: '#bebeff',
      dark: '#bebeff',
      contrastText: '#bebeff',
      main: '#bebeff',
    },
  },
});
