import { useNavigate, NavLink } from 'react-router-dom';
import styles from './Header.module.css';
import { REACT_APP_API_ENDPOINT } from '../../conf';
import { useAuth } from '../../hook/useAuth';

const Header = () => {
  const navigate = useNavigate();
  const { signout } = useAuth();

  const goBack = () => {
    window.localStorage.setItem('user', '');
    window.localStorage.setItem('password', '');
    window.localStorage.setItem('flagReadOnly', true);
    signout(navigate('/'));
  };

  return (
    <header className={styles.container}>
      <div className={styles.header}>
        <div className={styles.blockLinks}>
          <div>
            <NavLink to="/" className={styles.linkLogo}>
              <img src="" alt="" />
            </NavLink>
          </div>
        </div>
        <div className={styles.centralBlock}>
          <div className={styles.linkStyle}>
            <div className={styles.navStyle}>
              <NavLink
                end="true"
                to="/wallets"
                className={styles.link}
                style={({ isActive }) => ({
                  background: isActive
                    ? 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,187,177,1) 0%, rgba(6,120,189,1) 100%)'
                    : 'rgba(117, 190, 218, 0.0)',
                })}
              >
                Wallets
              </NavLink>
              <NavLink
                end="true"
                to="/subaccounts"
                className={styles.link}
                style={({ isActive }) => ({
                  background: isActive
                    ? 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,187,177,1) 0%, rgba(6,120,189,1) 100%)'
                    : 'rgba(117, 190, 218, 0.0)',
                })}
              >
                Exchanges
              </NavLink>
            </div>
          </div>
        </div>
        <div className={styles.buttonDiv}>
          <div className={styles.validityBlock}>
            <button className={styles.buttonLogin}>
              <a
                className={styles.buttonText}
                target="_blank"
                rel="noreferrer"
                href={`${REACT_APP_API_ENDPOINT}prices`}
              >
                Prices
              </a>
            </button>
            <button className={styles.buttonLogin}>
              <a
                className={styles.buttonText}
                target="_blank"
                rel="noreferrer"
                href="/balances"
              >
                Balances
              </a>
            </button>
          </div>
        </div>
        <div className={styles.buttonDiv}>
          <div className={styles.validityBlock}>
            <button className={styles.buttonLogin} onClick={goBack}>
              <p className={styles.buttonText}>log off</p>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
