import { createContext, useState } from 'react';

export const AuthContent = createContext(null);
// eslint-disable-next-line
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(window.localStorage.getItem('user'));

  const [password, setPassword] = useState(
    window.localStorage.getItem('password'),
  );

  // eslint-disable-next-line
  const signin = (newUser, cb) => {
    setUser(newUser);
    setPassword(newUser);
    // cb();
  };

  const signout = () => {
    setUser(null);
    setPassword(null);
  };

  // eslint-disable-next-line
  const value = { user, password, signin, signout };
  // const value = {user, password,  signout};

  return <AuthContent.Provider value={value}>{children}</AuthContent.Provider>;
};
