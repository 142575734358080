import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import base64 from 'base-64';
import axios from 'axios';
import {
  fieldUser,
  fieldPassword,
  fieldFlagReadOnly,
} from '../../redux/reducers/tokenReducer';
import { useAuth } from '../../hook/useAuth';
import { REACT_APP_API_ENDPOINT } from '../../conf';
import style from './Login.module.css';
import { CenteredBox } from '../../components/Containers/Box';
import Logo from '../../images/logo_login.svg';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { signin } = useAuth();
  const fromPage = location.state?.from?.pathname || '/wallets';

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const newUser = form.username.value;
    const newPassword = form.password.value;
    const text = ':';
    const textBasic = 'Basic ';

    const graphqlRefresh = JSON.stringify({
      query: `{currentUser{name, readonly}}`,
    });

    dispatch(fieldUser(newUser));
    dispatch(fieldPassword(newPassword));

    axios
      .post(REACT_APP_API_ENDPOINT, graphqlRefresh, {
        headers: {
          Authorization:
            textBasic + base64.encode(newUser + text + newPassword),
        },
      })
      .then((res) => {
        if (res.data.data) {
          axios
            .post(`${REACT_APP_API_ENDPOINT}`, graphqlRefresh, {
              headers: {
                Authorization:
                  textBasic + base64.encode(newUser + text + newPassword),
              },
            })
            .then((data) => {
              fieldFlagReadOnly(data.data.data.currentUser.readonly);
              signin(
                newUser + text + newPassword,
                navigate(fromPage, { replace: true }),
              );
            })
            .catch(() => {
              fieldFlagReadOnly(true);
              signin(
                newUser + text + newPassword,
                navigate(fromPage, { replace: true }),
              );
            });
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  };

  return (
    <CenteredBox className={style.loginWrapper}>
      <div className={style.style_all_content}>
        <div className={style.content}>
          <h1 className={style.title}>Authorization</h1>
          <form className={style.form_inputs} onSubmit={handleSubmit}>
            <input
              name="username"
              placeholder="Username"
              className={style.style_input}
            />
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Password"
              className={style.style_input}
            />
            <button className={style.style_input_button} type="submit">
              Send
            </button>
          </form>
        </div>
      </div>
      <Box className={style.loginBottomBox}>
        <Typography fontSize="14px">Powered by</Typography>
        <img src={Logo} alt="Linkmint" className={style.loginLogo} />
      </Box>
    </CenteredBox>
  );
};
export default Login;
