// import React from 'react';
import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

/* type GradientBoxProps = BoxProps & {
  sx?: object | object[];
  children: React.ReactNode;
};
const GradientBox = (props: GradientBoxProps) => (
  <Box
    sx={{
      marginTop: '20px',
      width: '100%',
      padding: '20px',
      borderRadius: '4px',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      ...props.sx,
    }}
    className={props.className}
  >
    {props.children}
  </Box>
);

type ChildrenBoxProps = BoxProps & {
  children: React.ReactNode;
  sx?: object | object[];
};
const SpaceBetweenBox = (props: ChildrenBoxProps) => (
  <Box
    sx={{ justifyContent: 'space-between', ...props.sx }}
    display="inline-flex"
    flexDirection="row"
    width="100%"
  >
    {props.children}
  </Box>
);

const PreviewWrapper = styled(Box)<BoxProps>(() => ({
  maxWidth: '540px',
  margin: '0px auto auto auto',
  width: '-webkit-fill-available',
}));

const TextBox = styled(Box)<BoxProps>(() => ({
  display: 'inline-flex',
  alignItems: 'center',
}));
*/

export const CenteredBox = styled(Box)<BoxProps>(() => ({
  width: '100%',
  justifyContent: 'center',
  textAlign: 'center',
}));
