import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import base64 from 'base-64';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import style from './Subaccounts.module.css';
import Header from '../../components/Header/Header';
import { REACT_APP_API_ENDPOINT } from '../../conf';
import ModalAddSubaccounts from '../../components/Modals/ModalAddSubaccounts/ModalAddSubaccounts';

const stylesChangeName = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#060721',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const stylesButtonDeleteWallets = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#060721',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Subaccounts = () => {
  const { user, password, flagReadOnly } = useSelector(
    (state) => state.tokenReducer,
  );

  const textAuth = ':';
  const [flagReadOnlyNow, setFlagReadOnlyNow] = useState(true);
  const [wallets, setWallets] = useState([]);
  const [nameOrder, setNameOrder] = useState(true);
  const [addName, setAddName] = useState('');
  const [opens, setOpens] = useState(false);
  const [subaccountId, setSubaccountId] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [deletes, setDeletes] = useState(false);
  const [openAddSubaccounts, setOpenAddSubaccounts] = useState(false);
  const handleCloseAddSubaccounts = () => setOpenAddSubaccounts(false);

  function getSubaccountsList() {
    const graphql = JSON.stringify({
      query:
        '{ subaccounts (sortBy: NAME, sortOrder: ASC){ id, name, platform, key, addTime }}',
      variables: {},
    });

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(`${REACT_APP_API_ENDPOINT}`, graphql, {
        headers: {
          Authorization: textB + base64.encode(user + text + password),
        },
      })
      .then((res) => {
        setWallets(res.data.data.subaccounts);
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  function sortByName() {
    setNameOrder(!nameOrder);
    const graphql = JSON.stringify({
      query: `{ subaccounts (sortBy: NAME, sortOrder: ${
        nameOrder ? 'ASC' : 'DESC'
      }){ id, name, platform, key, addTime }}`,
      variables: {},
    });

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(`${REACT_APP_API_ENDPOINT}`, graphql, {
        headers: {
          Authorization: textB + base64.encode(user + text + password),
        },
      })
      .then((res) => {
        setWallets(res.data.data.subaccounts);
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  function updateNames() {
    const query = `mutation { updateSubaccount (id: ${subaccountId}, update: {name: "${addName}"})}`;

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(
        `${REACT_APP_API_ENDPOINT}`,
        { query },
        {
          headers: {
            Authorization: textB + base64.encode(user + text + password),
          },
        },
      )
      .then((res) => {
        if ('errors' in res.data) {
          setUrlError(true);
        } else {
          setUrlError(false);
          setOpens(false);
          getSubaccountsList();
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  function deleteWallet() {
    const query = `mutation { deleteSubaccount (id: ${subaccountId})}`;

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(
        `${REACT_APP_API_ENDPOINT}`,
        {
          query,
        },
        {
          headers: {
            Authorization: textB + base64.encode(user + text + password),
          },
        },
      )
      .then((res) => {
        if ('errors' in res.data) {
          setUrlError(true);
        } else {
          setUrlError(false);
          setDeletes(false);
          getSubaccountsList();
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getSubaccountsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const flag = window.localStorage.getItem('flagReadOnly');
    if (flag === 'false') setFlagReadOnlyNow(false);
    else setFlagReadOnlyNow(true);
  }, [flagReadOnly]);

  return (
    <>
      <Header />
      <div className={style.all_content}>
        <div className={style.all_button}>
          <div className={style.two_btns}>
            {!flagReadOnlyNow && (
              <div>
                <Button
                  sx={{
                    'padding-left': '3px',
                    'padding-right': '3px',
                  }}
                  onClick={() => setOpenAddSubaccounts(true)}
                  variant="outlined"
                >
                  Add Exchange
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={style.all_content}>
        <div className={style.table}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell
                    onClick={() => {
                      sortByNumber('NUMBER');
                    }}
                    sx={{
                      minWidth: '110px',
                      cursor: 'pointer',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                  >
                    Number
                    {numberOrder ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </StyledTableCell> */}
                  <StyledTableCell
                    sx={{
                      padding: '5px',
                      borderBottomWidth: '1px !important',
                    }}
                    align="left"
                  >
                    Platform
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      sortByName();
                    }}
                    sx={{
                      minWidth: '150px',
                      cursor: 'pointer',
                      padding: '5px',
                      textAlign: 'left',
                      borderBottomWidth: '1px !important',
                    }}
                  >
                    Name
                    {!nameOrder ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      minWidth: '110px',
                      padding: '5px',
                      textAlign: 'left',
                      borderBottomWidth: '1px !important',
                    }}
                  >
                    Key
                  </StyledTableCell>
                  {/* <StyledTableCell
                    onClick={() => {
                      sortClaimedAmounts('CLAIMED_AMOUNT');
                    }}
                    sx={{
                      minWidth: '110px',
                      cursor: 'pointer',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                  >
                    Time
                    {orderClaimedAmount ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </StyledTableCell> */}
                  {!flagReadOnlyNow && (
                    <StyledTableCell
                      sx={{
                        padding: '5px',
                        borderBottomWidth: '1px !important',
                      }}
                      align="center"
                    >
                      Update/Delete
                    </StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {wallets &&
                  wallets.map((row) => (
                    <StyledTableRow key={row.id}>
                      {/* <StyledTableCell
                        sx={{
                          padding: '0px',
                          'text-align': 'center',
                          cursor: 'pointer',
                        }}
                        align="center"
                      >
                        {row.id}
                      </StyledTableCell> */}
                      <StyledTableCell
                        align="left"
                        component="th"
                        scope="item"
                        sx={{
                          padding: '0px',
                          cursor: 'pointer',
                          color: '#ffa726',
                          borderBottomWidth: '1px !important',
                        }}
                      >
                        {row.platform}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          padding: '0px',
                          'text-align': 'left',
                          borderBottomWidth: '1px !important',
                        }}
                        align="left"
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          padding: '0px',
                          borderBottomWidth: '1px !important',
                        }}
                        align="left"
                      >
                        {row.key}
                      </StyledTableCell>
                      {/* <StyledTableCell sx={{ padding: '0px' }} align="right">
                        {row.addTime}
                      </StyledTableCell> */}
                      {!flagReadOnlyNow && (
                        <StyledTableCell
                          sx={{
                            padding: '0px',
                            borderBottomWidth: '1px !important',
                          }}
                          align="center"
                          component="th"
                          scope="item"
                        >
                          <div className={style.buttonStyle}>
                            <Button
                              onClick={() => {
                                setOpens(true);
                                setSubaccountId(row.id);
                                setAddName(row.name);
                              }}
                            >
                              <CreateIcon />
                            </Button>
                            <Button
                              onClick={() => {
                                setDeletes(true);
                                setSubaccountId(row.id);
                              }}
                            >
                              <DeleteIcon color="action" />
                            </Button>
                          </div>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell
                    onClick={() => {
                      sortByNumber('NUMBER');
                    }}
                    sx={{
                      minWidth: '110px',
                      cursor: 'pointer',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                  >
                    Number
                    {numberOrder ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </StyledTableCell> */}
                  <StyledTableCell
                    sx={{
                      padding: '5px',
                      borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                    }}
                    align="left"
                  >
                    Platform
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      sortByName();
                    }}
                    sx={{
                      minWidth: '150px',
                      cursor: 'pointer',
                      padding: '5px',
                      textAlign: 'left',
                      borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                    }}
                  >
                    Name
                    {!nameOrder ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      minWidth: '110px',
                      padding: '5px',
                      textAlign: 'left',
                      align: 'left',
                      borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                    }}
                  >
                    Key
                  </StyledTableCell>
                  {/* <StyledTableCell
                    onClick={() => {
                      sortClaimedAmounts('CLAIMED_AMOUNT');
                    }}
                    sx={{
                      minWidth: '110px',
                      cursor: 'pointer',
                      padding: '5px',
                      textAlign: 'center',
                    }}
                  >
                    Time
                    {orderClaimedAmount ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <KeyboardArrowUpIcon />
                    )}
                  </StyledTableCell> */}
                  {!flagReadOnlyNow && (
                    <StyledTableCell
                      sx={{
                        padding: '5px',
                        borderTop:
                          '1px solid rgba(224, 224, 224, 1) !important',
                      }}
                      align="center"
                    >
                      Update/Delete
                    </StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </div>

        <div>
          <Modal
            align="center"
            open={opens}
            onClose={() => setOpens(false)}
            aria-labelledby="modal-modal"
            aria-describedby="modal-modal-description"
          >
            <Box sx={stylesChangeName}>
              <Typography
                id="modal-modal"
                variant="h6"
                component="h2"
                align="center"
              >
                Enter your name
              </Typography>
              <TextField
                error={urlError}
                sx={{ m: 1, width: '45ch' }}
                id="outlined-basic"
                label="name"
                value={addName}
                onChange={(e) => setAddName(e.target.value)}
              />
              <div className={style.btns_add_exit}>
                <Button
                  onClick={() => updateNames()}
                  sx={{ mr: 15 }}
                  variant="outlined"
                >
                  Save change
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setUrlError(false);
                    setOpens(false);
                  }}
                  autoFocus
                >
                  Exit
                </Button>
              </div>
            </Box>
          </Modal>
          <Modal
            align="center"
            open={deletes}
            onClose={() => setDeletes(false)}
            aria-labelledby="modal-modal"
            aria-describedby="modal-modal-description"
          >
            <Box sx={stylesButtonDeleteWallets}>
              <Typography
                id="modal-modal"
                variant="h6"
                component="h2"
                align="center"
              >
                Are you sure you want to delete?
              </Typography>
              <div className={style.btns_add_exit}>
                <Button
                  onClick={() => deleteWallet()}
                  sx={{ mr: 15, mt: 5 }}
                  variant="outlined"
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setDeletes(false)}
                  sx={{ mt: 5 }}
                  autoFocus
                >
                  Close
                </Button>
              </div>
            </Box>
          </Modal>
          <ModalAddSubaccounts
            open={openAddSubaccounts}
            handleClose={handleCloseAddSubaccounts}
            auth={base64.encode(user + textAuth + password)}
          />
        </div>
      </div>
    </>
  );
};

export default Subaccounts;
