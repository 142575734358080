import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../hook/useAuth';

// eslint-disable-next-line
const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { user, password } = useAuth();

  if (!user || !password) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
};

export default RequireAuth;
