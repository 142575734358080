import PropTypes from 'prop-types';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import styles from './ModalAddWallet.module.css';
import { REACT_APP_API_ENDPOINT } from '../../../conf';

const stylesModalAddWallets = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#060721',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalAddWallet = (props) => {
  const [newAddres, setNewAddres] = useState('');
  const [newName, setNewName] = useState('');
  const [newBlockchain, setNewBlockchain] = useState('');
  const [adressError, setAdressError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');
  const textB = 'Basic ';

  function getMainData() {
    window.location.reload();
  }

  function addNewData() {
    if (newAddres.trim().length && newName.trim().length) {
      const query = `mutation {
        addWallet(wallet:{address: "${newAddres}", name: "${newName}", blockchain: "${newBlockchain}"})}`;
      axios
        .post(
          `${REACT_APP_API_ENDPOINT}`,
          {
            query,
          },
          { headers: { Authorization: textB + props.auth } },
        )
        .then((res) => {
          if ('errors' in res.data) {
            setErrorMessages(res.data.errors[0].message);
          } else {
            getMainData();
            setNewBlockchain('');
            setNewAddres('');
            setNewName('');
            setAdressError(false);
            setUrlError(false);
          }
        })
        // eslint-disable-next-line
        .catch((err) => console.log(err));
    } else setAdressError(true);
  }
  return (
    <Modal
      align="center"
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={stylesModalAddWallets}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align="center"
        >
          Add Wallet
        </Typography>
        <TextField
          error={adressError}
          autoComplete="off"
          sx={{ m: 1, width: '45ch' }}
          id="outlined-basic"
          label="address"
          variant="outlined"
          value={newAddres}
          onChange={(e) => setNewAddres(e.target.value)}
        />
        <TextField
          autoComplete="off"
          sx={{ m: 1, width: '45ch' }}
          id="outlined-basic"
          label="name"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <FormControl>
          <InputLabel id="outlined-basic" sx={{ m: 1 }}>
            Blockchain
          </InputLabel>
          <Select
            id="outlined-basic"
            sx={{ m: 1, width: '20ch' }}
            value={newBlockchain}
            variant="outlined"
            label="Blockchain"
            onChange={(e) => setNewBlockchain(e.target.value)}
          >
            <MenuItem value="ETHEREUM">Ethereum (ERC20)</MenuItem>
            <MenuItem value="TRON">Tron (TRC20)</MenuItem>
            <MenuItem value="BNB">BNB (BEP20)</MenuItem>
          </Select>
        </FormControl>
        {errorMessages && (
          <div className={styles.errorText}>{errorMessages}</div>
        )}
        <div className={styles.btn_add_clear}>
          <Button
            onClick={() => addNewData()}
            sx={{ mr: 15 }}
            variant="outlined"
          >
            Add
          </Button>
          <Button variant="outlined" onClick={props.handleClose} autoFocus>
            Exit
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
ModalAddWallet.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  auth: PropTypes.string,
};
export default ModalAddWallet;
