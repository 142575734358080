import { useSelector } from 'react-redux';
import Split from 'react-split';
import { useState, useEffect } from 'react';
import base64 from 'base-64';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import style from './Balances.module.css';
import Header from '../../components/Header/Header';
import { REACT_APP_API_ENDPOINT } from '../../conf';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Balances = () => {
  const { user, password } = useSelector((state) => state.tokenReducer);

  const [walletsLog, setWalletsLog] = useState([]);
  const [subaccountLog, setSubaccountLog] = useState([]);
  const [walletBalanceOrder, setWalletBalanceOrder] = useState(true);
  const [walletTimepointOrder, setWalletTimepointOrder] = useState(true);
  const [subaccountBalanceOrder, setSubaccountBalanceOrder] = useState(true);

  const [subaccountTimepointOrder, setSubaccountTimepointOrder] =
    useState(true);

  function getWalletLogList() {
    const graphql = JSON.stringify({
      query:
        '{ walletLogs (sortBy: TIMEPOINT, sortOrder: DESC){ wallet {id, name, blockchain, address }, timepoint, currency, balance }}',
      variables: {},
    });

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(`${REACT_APP_API_ENDPOINT}`, graphql, {
        headers: {
          Authorization: textB + base64.encode(user + text + password),
        },
      })
      .then((res) => {
        setWalletsLog(res.data.data.walletLogs);
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  function getSubaccountLogList() {
    const graphql = JSON.stringify({
      query:
        '{ subaccountLogs (sortBy: TIMEPOINT, sortOrder: DESC){ subaccount {id, name, platform}, timepoint, currency, balance }}',
      variables: {},
    });

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(`${REACT_APP_API_ENDPOINT}`, graphql, {
        headers: {
          Authorization: textB + base64.encode(user + text + password),
        },
      })
      .then((res) => {
        setSubaccountLog(res.data.data.subaccountLogs);
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  function sortWalletLogByBalance() {
    setWalletBalanceOrder(!walletBalanceOrder);
    const graphql = JSON.stringify({
      query: `{ walletLogs (sortBy: CURRENCY, sortOrder: ${
        walletBalanceOrder ? 'ASC' : 'DESC'
      }){ wallet {id, name, blockchain, address }, timepoint, currency, balance }}`,
      variables: {},
    });

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(`${REACT_APP_API_ENDPOINT}`, graphql, {
        headers: {
          Authorization: textB + base64.encode(user + text + password),
        },
      })
      .then((res) => {
        setWalletsLog(res.data.data.walletLogs);
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  function sortWalletLogByTimepoint() {
    setWalletTimepointOrder(!walletTimepointOrder);
    const graphql = JSON.stringify({
      query: `{ walletLogs (sortBy: TIMEPOINT, sortOrder: ${
        walletTimepointOrder ? 'ASC' : 'DESC'
      }){ wallet {id, name, blockchain, address }, timepoint, currency, balance }}`,
      variables: {},
    });

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(`${REACT_APP_API_ENDPOINT}`, graphql, {
        headers: {
          Authorization: textB + base64.encode(user + text + password),
        },
      })
      .then((res) => {
        setWalletsLog(res.data.data.walletLogs);
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  function sortSubaccountLogByBalance() {
    setSubaccountBalanceOrder(!subaccountBalanceOrder);
    const graphql = JSON.stringify({
      query: `{ subaccountLogs (sortBy: CURRENCY, sortOrder: ${
        subaccountBalanceOrder ? 'ASC' : 'DESC'
      }){ subaccount {id, name, platform}, timepoint, currency, balance }}`,
      variables: {},
    });

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(`${REACT_APP_API_ENDPOINT}`, graphql, {
        headers: {
          Authorization: textB + base64.encode(user + text + password),
        },
      })
      .then((res) => {
        setSubaccountLog(res.data.data.subaccountLogs);
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  function sortSubaccountLogByTimepoint() {
    setSubaccountTimepointOrder(!subaccountTimepointOrder);
    const graphql = JSON.stringify({
      query: `{ subaccountLogs (sortBy: TIMEPOINT, sortOrder: ${
        subaccountTimepointOrder ? 'ASC' : 'DESC'
      }){ subaccount {id, name, platform}, timepoint, currency, balance }}`,
      variables: {},
    });

    const text = ':';
    const textB = 'Basic ';

    axios
      .post(`${REACT_APP_API_ENDPOINT}`, graphql, {
        headers: {
          Authorization: textB + base64.encode(user + text + password),
        },
      })
      .then((res) => {
        setSubaccountLog(res.data.data.subaccountLogs);
      })
      // eslint-disable-next-line
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getWalletLogList();
    getSubaccountLogList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <Split
        className={style.splitTest}
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
        sizes={[50, 50]}
        minSize={100}
        gutterSize={6}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderBottomWidth: '1px !important',
                  }}
                  align="center"
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderBottomWidth: '1px !important',
                  }}
                  align="center"
                >
                  Blockchain
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderBottomWidth: '1px !important',
                  }}
                  align="center"
                >
                  Address
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderBottomWidth: '1px !important',
                  }}
                  align="center"
                  onClick={() => {
                    sortWalletLogByTimepoint();
                  }}
                >
                  Time
                  {!walletTimepointOrder ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderBottomWidth: '1px !important',
                  }}
                  align="center"
                  onClick={() => {
                    sortWalletLogByBalance();
                  }}
                >
                  Balance
                  {!walletBalanceOrder ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletsLog &&
                walletsLog.map((row, index) => (
                  // eslint-disable-next-line
                  <StyledTableRow key={row.timepoint + index}>
                    <StyledTableCell
                      sx={{
                        padding: '0px',
                        borderBottomWidth: '1px !important',
                      }}
                      align="left"
                    >
                      {console.log(index, 'index')}
                      {row.wallet.name}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        padding: '0px',
                        borderBottomWidth: '1px !important',
                      }}
                      align="left"
                    >
                      {row.wallet.blockchain}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        padding: '0px',
                        borderBottomWidth: '1px !important',
                      }}
                      align="left"
                    >
                      {row.wallet.address}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        padding: '0px',
                        borderBottomWidth: '1px !important',
                      }}
                      align="left"
                    >
                      {row.timepoint}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        padding: '0px',
                        textAlign: 'left',
                        borderBottomWidth: '1px !important',
                      }}
                      align="left"
                    >
                      {row.balance} {row.currency}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                  }}
                  align="center"
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                  }}
                  align="center"
                >
                  Blockchain
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                  }}
                  align="center"
                >
                  Address
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                  }}
                  align="center"
                  onClick={() => {
                    sortWalletLogByTimepoint();
                  }}
                >
                  Time
                  {!walletTimepointOrder ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                  }}
                  align="center"
                  onClick={() => {
                    sortWalletLogByBalance();
                  }}
                >
                  Balance
                  {!walletBalanceOrder ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderBottomWidth: '1px !important',
                  }}
                  align="center"
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderBottomWidth: '1px !important',
                  }}
                  align="center"
                >
                  Platform
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderBottomWidth: '1px !important',
                  }}
                  align="center"
                  onClick={() => {
                    sortSubaccountLogByTimepoint();
                  }}
                >
                  Time
                  {!subaccountTimepointOrder ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderBottomWidth: '1px !important',
                  }}
                  align="center"
                  onClick={() => {
                    sortSubaccountLogByBalance();
                  }}
                >
                  Balance
                  {!subaccountBalanceOrder ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subaccountLog &&
                subaccountLog.map((row, index) => (
                  // eslint-disable-next-line
                  <StyledTableRow key={row.timepoint + index}>
                    <StyledTableCell
                      sx={{
                        padding: '0px',
                        borderBottomWidth: '1px !important',
                      }}
                      align="left"
                    >
                      {row.subaccount.name}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        padding: '0px',
                        borderBottomWidth: '1px !important',
                      }}
                      align="left"
                    >
                      {row.subaccount.platform}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        padding: '0px',
                        borderBottomWidth: '1px !important',
                      }}
                      align="left"
                    >
                      {row.timepoint}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        padding: '0px',
                        textAlign: 'left',
                        borderBottomWidth: '1px !important',
                      }}
                      align="left"
                    >
                      {row.balance} {row.currency}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                  }}
                  align="center"
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                  }}
                  align="center"
                >
                  Platform
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                  }}
                  align="center"
                  onClick={() => {
                    sortSubaccountLogByTimepoint();
                  }}
                >
                  Time
                  {!subaccountTimepointOrder ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    padding: '5px',
                    borderTop: '1px solid rgba(224, 224, 224, 1) !important',
                  }}
                  align="center"
                  onClick={() => {
                    sortSubaccountLogByBalance();
                  }}
                >
                  Balance
                  {!subaccountBalanceOrder ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Split>
    </>
  );
};
export default Balances;
