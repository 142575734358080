export const USER = 'USER';
export const PASSWORD = 'PASSWORD';
export const FLAG_READ_ONLY = 'FLAG_READ_ONLY';

const initialState = {
  user: window.localStorage.getItem('user'),
  password: window.localStorage.getItem('password'),
  flagReadOnly: window.localStorage.getItem('flagReadOnly'),
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER:
      return {
        ...state,
        user: action.user,
      };
    case PASSWORD:
      return {
        ...state,
        password: action.password,
      };
    case FLAG_READ_ONLY:
      return {
        ...state,
        flagReadOnly: action.flagReadOnly,
      };
    default:
      return state;
  }
};

export const fieldFlagReadOnly = (flagReadOnly) => {
  window.localStorage.setItem('flagReadOnly', flagReadOnly);
  return { type: FLAG_READ_ONLY, flagReadOnly };
};

export const fieldUser = (user) => {
  window.localStorage.setItem('user', user);
  return { type: USER, user };
};

export const fieldPassword = (password) => {
  window.localStorage.setItem('password', password);
  return { type: PASSWORD, password };
};
export default tokenReducer;
