import PropTypes from 'prop-types';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import styles from './ModalAddSubaccounts.module.css';
import { REACT_APP_API_ENDPOINT } from '../../../conf';

const stylesModalAddWallets = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#060721',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalAddSubaccounts = (props) => {
  const [newPlatform, setNewPlatform] = useState('');
  const [newName, setNewName] = useState('');
  const [newKey, setNewKey] = useState('');
  const [newSecret, setNewSecret] = useState('');
  const [newPassphrase, setNewPassphrase] = useState('');
  const [adressError, setAdressError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');
  const textB = 'Basic ';

  function getMainData() {
    window.location.reload();
  }

  function addNewData() {
    if (newName.trim().length) {
      const query = `mutation {
        addSubaccount(subaccount:{platform: "${newPlatform}", name: "${newName}", key: "${newKey}", secret: "${newSecret}", passphrase: "${newPassphrase}"})}`;
      axios
        .post(
          `${REACT_APP_API_ENDPOINT}`,
          {
            query,
          },
          { headers: { Authorization: textB + props.auth } },
        )
        .then((res) => {
          if ('errors' in res.data) {
            setErrorMessages(res.data.errors[0].message);
          } else {
            getMainData();
            setNewPlatform('');
            setNewName('');
            setNewKey('');
            setNewSecret('');
            setNewPassphrase('');
            setAdressError(false);
            setUrlError(false);
          }
        })
        // eslint-disable-next-line
        .catch((err) => console.log(err));
    } else setAdressError(true);
  }
  return (
    <Modal
      align="center"
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={stylesModalAddWallets}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align="center"
        >
          Add Exchange
        </Typography>
        <FormControl>
          <InputLabel id="outlined-basic" sx={{ m: 1 }}>
            Platform
          </InputLabel>
          <Select
            id="outlined-basic"
            sx={{ m: 1, width: '20ch' }}
            value={newPlatform}
            variant="outlined"
            label="Platform"
            onChange={(e) => setNewPlatform(e.target.value)}
          >
            <MenuItem value="KUCOIN">Kucoin</MenuItem>
            <MenuItem value="GATE_IO">Gate.io</MenuItem>
            <MenuItem value="MEXC">MEXC</MenuItem>
            {/* <MenuItem value="Binance">Binance</MenuItem>
            <MenuItem value="BITTREX">Bittrex</MenuItem>
            <MenuItem value="OKX">OKX</MenuItem>
            <MenuItem value="Bybit">Bybit</MenuItem>
            <MenuItem value="Coinbase">Coinbase</MenuItem>
            <MenuItem value="Binance.US">Binance.US</MenuItem>
            <MenuItem value="Upbit">Upbit</MenuItem> */}
          </Select>
        </FormControl>
        <TextField
          autoComplete="off"
          sx={{ m: 1, width: '45ch' }}
          id="outlined-basic"
          label="name"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <TextField
          error={urlError}
          autoComplete="off"
          sx={{ m: 1, width: '45ch' }}
          id="outlined-basic"
          label="Key"
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
        />
        <TextField
          error={urlError}
          autoComplete="off"
          sx={{ m: 1, width: '45ch' }}
          id="outlined-basic"
          label="Secret"
          value={newSecret}
          onChange={(e) => setNewSecret(e.target.value)}
        />
        <TextField
          error={urlError}
          autoComplete="off"
          sx={{ m: 1, width: '45ch' }}
          id="outlined-basic"
          label="Passphrase"
          value={newPassphrase}
          onChange={(e) => setNewPassphrase(e.target.value)}
        />
        {errorMessages && (
          <div className={styles.errorText}>{errorMessages}</div>
        )}
        <div className={styles.btn_add_clear}>
          <Button
            onClick={() => addNewData()}
            sx={{ mr: 15 }}
            variant="outlined"
          >
            Add
          </Button>
          <Button variant="outlined" onClick={props.handleClose} autoFocus>
            Exit
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
ModalAddSubaccounts.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  auth: PropTypes.string,
};

export default ModalAddSubaccounts;
