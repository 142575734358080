import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routes, Route } from 'react-router-dom';
import Wallets from './pages/Wallets/Wallets';
import Subaccounts from './pages/Subaccounts/Subaccounts';
import Balances from './pages/Balances/Balances';
import Login from './pages/Login/Login';
import './App.css';
import RequireAuth from './hoc/RequireAuth';
import { AuthProvider } from './hoc/AuthProvider';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route
            path="/wallets"
            element={
              <RequireAuth>
                <Wallets />
              </RequireAuth>
            }
          />
          <Route
            path="/subaccounts"
            element={
              <RequireAuth>
                <Subaccounts />
              </RequireAuth>
            }
          />
          <Route
            path="/balances"
            element={
              <RequireAuth>
                <Balances />
              </RequireAuth>
            }
          />
          <Route path="/" element={<Login />} />
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
